<template>
  <div class="table_wrapper">
    <v-card flat tile>
      <v-toolbar class="rounded">
        <v-btn icon>
          <v-icon>mdi-arrow-left</v-icon>
        </v-btn>

        <v-toolbar-title>Coupons Edit</v-toolbar-title>

        <v-spacer></v-spacer>

        <v-btn color="primary" v-on:click="saveAllData" dark>
          <v-icon>mdi-check</v-icon>
          Save
        </v-btn>
      </v-toolbar>

      <v-layout class="p-5" wrap row>
        <v-col cols="6">
          <v-text-field
            label="Code"
            v-model="data.code"
            :loading="loading"
            required
          ></v-text-field>
        </v-col>
        <v-col cols="6">
          <v-text-field
            label="Description"
            v-model="data.description"
            :loading="loading"
          ></v-text-field>
        </v-col>
        <v-col cols="6">
          <v-text-field
            label="Discount"
            v-model="data.percent"
            :loading="loading"
          ></v-text-field>
        </v-col>
        <v-col cols="6">
          <v-switch label="Is Used" v-model="data.is_used"></v-switch>
        </v-col>
        <v-col cols="6">
          <v-switch label="Is Expired" v-model="data.is_expired"></v-switch>
        </v-col>
        <v-col cols="6">
          <v-menu
            ref="menu"
            v-model="menu"
            :close-on-content-click="false"
            :return-value.sync="data.expire_date"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="data.expire_date"
                label="Expire Date"
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker v-model="data.expire_date" no-title scrollable>
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="menu = false">
                Cancel
              </v-btn>
              <v-btn
                text
                color="primary"
                @click="$refs.menu.save(data.expire_date)"
              >
                OK
              </v-btn>
            </v-date-picker>
          </v-menu>
        </v-col>
      </v-layout>
    </v-card>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import axios from "axios";

export default {
  name: "CouponsEdit",
  data: () => ({
    loading: true,
    menu: false,
    token: localStorage.getItem("authToken"),
    data: {
      code: "",
      description: "",
      expire_date: "",
      id: null,
      is_expired: null,
      is_used: null,
      percent: "",
      user_id: null,
    },
  }),
  methods: {
    saveAllData: function() {
      this.loading = true;
      this.data.id = Number(this.$route.params.id);

      axios
        .put(
          process.env.VUE_APP_API_URL +
            "coupon/" +
            this.$route.params.id +
            "?api_token=" +
            this.token,
          this.data
        )
        .then((res) => {
          this.$router.push({
            name: "Coupons",
          });
        })
        .catch((err) => console.log(err));
    },
    getData: function(id) {
      let url =
        "https://gowawe.com/api/admin/coupon/" +
        id +
        "&api_token=" +
        this.token;
      axios
        .get(url)
        .then((res) => {
          this.loading = false;
          this.data = res.data;
        })
        .catch((err) => console.log(err));
    },
  },
  mounted() {
    this.getData(this.$route.params.id);
  },
  computed: {
    ...mapGetters("auth", ["user"]),
  },
};
</script>
